import React from "react"
import Layout from "../components/layout"
import { Link } from 'gatsby'

export default () => (
  <Layout styles="py-5"
      title="404"
  >
    <div className="container py-5 text-center">
      <div className="row justify-content-center">
        <div className="col-xs-12 col-sm-9">
        <h2 className="display-1 text-center text-primary font-weight-bold">404</h2>
        <h3 className="display-4 p-5">Sorry, we couldn't find the page you are looking for.</h3>
        <h3 className="display-4"><Link to="/">Go Home</Link></h3>
        </div>
        </div>
        </div>
  </Layout>
)
